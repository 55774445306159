import { get } from '@/http/http'

// 告警趋势
export const alarmTrend = p => get('v1/analysisinfo/alarmtrend', p)

// 设备数量
export const equNumber = p => get('v1/analysisinfo/equipmentcount', p)

// 设备告警排行
export const equAlarmTop = p => get('v1/analysisinfo/equaralmsort', p)

// 告警处理
export const alarmDeal = p => get('v1/analysisinfo/alarmdeal', p)

// 告警统计
export const alarmCensus = p => get('v1/analysisinfo/alarmstats', p)

// 巡检统计
export const onSiteCensus = p => get('v1/analysisinfo/inspectionsta', p)

// 故障统计
export const faultTrend = p => get('v1/analysisinfo/faultstatis', p)

// 响应及时率
export const answerRate = p => get('v1/analysisinfo/alarmrate', p)
