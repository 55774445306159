<template>
<div class="panel_content">
  <div class="panel_cont">
    <div class="box100 flexCB">
      <!-- 左边 -->
      <div class="panel_l">
        <!-- 告警趋势 -->
        <div class="pl_item">
          <div class="homeItemBg flexAC">
            <span class="el-icon-caret-right qctIco"></span><span class="qctTitle">告警趋势</span>
          </div>
          <div class="boxOther echBox">
            <EchartsOne></EchartsOne>
          </div>
        </div>
        <!-- 设备数量 -->
        <div class="pl_item flexCB">
          <div class="plS_item">
            <div class="homeItemBg flexAC">
              <span class="el-icon-caret-right qctIco"></span><span class="qctTitle">设备数量</span>
            </div>
            <div class="boxOther echBox">
              <EchartsTwo></EchartsTwo>
            </div>
          </div>
          <!-- 告警处理情况 -->
          <div class="plS_item">
            <div class="homeItemBg flexAC">
              <span class="el-icon-caret-right qctIco"></span><span class="qctTitle">告警处理情况</span>
            </div>
            <div class="boxOther echBox">
              <EChartsThree></EChartsThree>
            </div>
          </div>
        </div>
        <div class="pl_item flexCB">
          <!-- 设备告警排行 -->
          <div class="plS_item">
            <div class="homeItemBg flexAC">
              <span class="el-icon-caret-right qctIco"></span><span class="qctTitle">设备告警排行</span>
            </div>
            <div class="boxOther echBox">
              <EChartsFour></EChartsFour>
            </div>
          </div>
          <!-- 巡检统计 -->
          <div class="plS_item">
            <div class="homeItemBg flexAC">
              <span class="el-icon-caret-right qctIco"></span><span class="qctTitle">巡检统计</span>
            </div>
            <div class="boxOther echBox">
              <EchartsFive></EchartsFive>
            </div>
          </div>
        </div>
      </div>

      <!-- 右边 -->
      <div class="panel_r">
        <!-- 告警统计 -->
        <div class="pr_item">
          <div class="homeItemBg flexAC">
            <span class="el-icon-caret-right qctIco"></span><span class="qctTitle">告警统计</span>
          </div>
          <div class="boxOther echBox">
            <EchartsSix></EchartsSix>
          </div>
        </div>
        <!-- 告警响应及时率 -->
        <div class="pr_item">
          <div class="homeItemBg flexAC">
            <span class="el-icon-caret-right qctIco"></span><span class="qctTitle">告警响应及时率</span>
          </div>
          <div class="boxOther echBox">
            <EchartsSeven></EchartsSeven>
          </div>
        </div>
        <!-- 故障统计 -->
        <div class="pr_item">
          <div class="homeItemBg flexAC">
            <span class="el-icon-caret-right qctIco"></span><span class="qctTitle">故障统计</span>
          </div>
          <div class="boxOther echBox">
            <EchartsEight></EchartsEight>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import EchartsOne from '@/components/dataPanel/echartsOne'
import EchartsTwo from '@/components/dataPanel/echartsTwo'
import EChartsThree from '@/components/dataPanel/echartsThree'
import EChartsFour from '@/components/dataPanel/echartsFour'
import EchartsFive from '@/components/dataPanel/echartsFive'
import EchartsSix from '@/components/dataPanel/echartsSix'
import EchartsSeven from '@/components/dataPanel/echartsSeven'
import EchartsEight from '@/components/dataPanel/echartsEight'
export default {
  name: 'dataPanel',
  components: {
    EchartsOne,
    EchartsTwo,
    EChartsThree,
    EChartsFour,
    EchartsFive,
    EchartsSix,
    EchartsSeven,
    EchartsEight
  }
}
</script>

<style scoped>
.panel_content{
  width: 100%;
  height: 100%;
  position: relative;
  background: url("../../assets/img/dataPanBg.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 70px;
  box-sizing: border-box;
}
.panel_cont{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px 40px;
  box-sizing: border-box;
}
.panel_l{
  width: 66%;
  height: 100%;
  position: relative;
}
.panel_r{
  width: 32%;
  height: 100%;
  position: relative;
}
.pr_item,.pl_item{
  width: 100%;
  height: 33.3%;
  position: relative;
}
.plS_item{
  width: 48%;
  height: 100%;
  position: relative;
}
.echBox{
  padding-top: 30px;
}
</style>
