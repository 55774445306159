<template>
  <div class="box100 threeBox">
    <div class="flexCE one_top">
      <p class="sd_p" v-for="item in sdDate" :key="item.index"
         :class="sdValue === item.label ? 'sd_active' : ''"
         @click="selectSD(item)">
        {{ item.name }}
      </p>
    </div>
    <div class="boxEch">
      <div style="width: 100%;height: 100%;" ref="panelEFive"></div>
    </div>
  </div>
</template>

<script>
import { onSiteCensus } from '@/http/api/datapanel'
var echarts = require('echarts')
export default {
  name: 'echartsThree',
  data () {
    return {
      companyid: '',
      sdDate: [{ name: '近7日', label: '1' }, { name: '近1月', label: '2' }],
      sdValue: '1',
      xArr: [],
      yBrr1: [],
      yBrr2: [],
      yBrr3: []
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    selectSD (item) {
      this.sdValue = item.label
      this.xArr = []
      this.yBrr1 = []
      this.yBrr2 = []
      this.yBrr3 = []
      this.init()
    },
    init () {
      const params = {
        companyid: this.companyid,
        ymd: this.sdValue
      }
      onSiteCensus(params).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            for (var i in res.data) {
              this.xArr.push(res.data[i].time)
              this.yBrr1.push(res.data[i].haspart)
              this.yBrr2.push(res.data[i].notpart)
              this.yBrr3.push(res.data[i].witpart)
            }
            this.drawEcharts()
          }
        }
      })
    },
    drawEcharts () {
      var echartsRef = echarts.init(this.$refs.panelEFive)
      var max = Math.max(...this.yBrr1)
      if (max > 5) {
        max = Math.max(...this.yBrr1)
      } else {
        max = 5
      }
      var option = {
        color: ['#33D162', '#4295FF', '#FF6242'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(136, 172, 252, 0.16)',
          borderColor: 'rgba(136, 172, 252, 0.16)',
          textStyle: { color: '#fffff' }
        },
        grid: {
          top: 30,
          bottom: '10%',
          left: '1%',
          right: '3%',
          containLabel: true
        },
        legend: {
          left: 0,
          top: -4,
          textStyle: { color: '#A5B7CC' },
          data: ['已完成', '待巡检', '已逾期']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          offset: 5,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { color: '#A5B7CC' },
          data: this.xArr
        },
        yAxis: {
          type: 'value',
          max: max,
          axisLabel: { color: '#A5B7CC' },
          splitLine: {
            lineStyle: {
              color: '#eee',
              width: 0.5,
              type: [2, 10],
              dashOffset: 2
            }
          }
        },
        series: [
          {
            name: '已完成',
            type: 'line',
            symbol: 'none',
            smooth: false,
            data: this.yBrr1,
            areaStyle: { opacity: 0.3 }
          },
          {
            name: '待巡检',
            type: 'line',
            symbol: 'none',
            smooth: false,
            data: this.yBrr2,
            areaStyle: { opacity: 0.3 }
          },
          {
            name: '已逾期',
            type: 'line',
            symbol: 'none',
            smooth: false,
            data: this.yBrr3,
            areaStyle: { opacity: 0.3 }
          }
        ]
      }
      echartsRef.setOption(option)
    }
  }
}
</script>

<style scoped>
.one_top{
  padding-top: 20px;
  padding-right: 3%;
  box-sizing: border-box;
  z-index: 10;
}
.boxEch{
  width: 100%;
  height: calc(100% - 55px);
}
</style>