<template> <div class="box100 threeBox">
  <div style="width: 100%;height: 100%;" ref="panelEEight"></div>
</div>
</template>

<script>
import { faultTrend } from '@/http/api/datapanel'
var echarts = require('echarts')
export default {
  name: 'echartsSeven',
  data () {
    return {
      companyid: '',
      isdeal: 0,
      nodeal: 0
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    init () {
      faultTrend({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.isdeal = res.data.isoknum
          this.nodeal = res.data.isnonum
        } else {
          this.isdeal = 0
          this.nodeal = 0
        }
        this.drawEcharts()
      })
    },
    drawEcharts () {
      var echartsRef = echarts.init(this.$refs.panelEEight)
      var option = {
        color: ['#33D162', '#FF6242'],
        legend: {
          itemWidth: 10,
          itemHeight: 10,
          orient: 'horizontal',
          bottom: 10,
          left: 'center',
          textStyle: { color: '#A5B7CC', fontSize: 12, lineHeight: 12 },
          data: ['已处理', '未处理']
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['38%', '56%'],
            hoverAnimation: false,
            top: -20,
            left: 'center',
            label: {
              formatter: '{d}% ({c}次)',
              color: '#9BEBF7',
              overflow: 'auto'
            },
            labelLine: { length: 10 },
            data: [
              { value: this.isdeal, name: '已处理' },
              { value: this.nodeal, name: '未处理' }
            ]
          },
          {
            name: '',
            type: 'pie',
            radius: ['65%', '66%'],
            hoverAnimation: false,
            top: -20,
            left: 'center',
            label: { show: false },
            itemStyle: {
              color: '#0B576E'
            },
            data: [{ value: 100 }]
          },
          {
            name: '',
            type: 'pie',
            radius: ['0%', '28%'],
            hoverAnimation: false,
            top: -20,
            left: 'center',
            label: { show: false },
            itemStyle: {
              color: 'rgba(136, 172, 252, 0.16)'
            },
            data: [{ value: 100 }]
          }]
      }
      echartsRef.setOption(option)
    }
  }
}
</script>

<style scoped>

</style>