<template>
  <div class="box100 oneBox">
    <div class="flexCE one_top">
      <el-select v-model="equType" placeholder="全部设备" class="setSe" @change="equSelect">
        <el-option v-for="item in typeList" :key="item.index"
                   :label="item.equtypename"
                   :value="item.equtypeid">
          {{ item.equtypename }}</el-option>
      </el-select>
      <div class="flexCB" style="margin-left: 40px;">
        <p class="sd_p" v-for="item in sdDate" :key="item.index"
           :class="sdValue === item.label ? 'sd_active' : ''"
           @click="selectSD(item)">
          {{ item.name }}
        </p>
      </div>
    </div>
    <div class="boxEch">
      <div style="width: 100%;height: 100%;" ref="panelEOne"></div>
    </div>
  </div>
</template>

<script>
import { getEquipType } from '@/http/api/equip'
import { alarmTrend } from '@/http/api/datapanel'
var echarts = require('echarts')
export default {
  name: 'echartsOne',
  data () {
    return {
      companyid: '',
      typeList: [],
      equType: '',
      sdDate: [{ name: '近7日', label: '1' }, { name: '近1月', label: '2' }, { name: '近1年', label: '3' }],
      sdValue: '1',
      xArr: [],
      yBrr: []
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.getEquType()
    this.init()
  },
  methods: {
    getEquType () {
      getEquipType().then(res => {
        if (res.status === '1') {
          this.typeList = res.data.data
          var _default = { equtypename: '全部设备', equtypeid: '' }
          this.typeList.unshift(_default)
        } else {
          this.typeList = ''
        }
      })
    },
    equSelect (val) {
      this.equType = val
      this.xArr = []
      this.yBrr = []
      this.init()
    },
    selectSD (item) {
      this.sdValue = item.label
      this.xArr = []
      this.yBrr = []
      this.init()
    },
    init () {
      const params = {
        companyid: this.companyid,
        equtype: this.equType,
        ymd: this.sdValue
      }
      alarmTrend(params).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            for (var i in res.data) {
              this.xArr.push(res.data[i].time)
              this.yBrr.push(res.data[i].count)
            }
            this.drawEcharts()
          }
        } else {}
      })
    },
    drawEcharts () {
      var echartsRef = echarts.init(this.$refs.panelEOne)
      var max = Math.max(...this.yBrr)
      if (max > 5) {
        max = Math.max(...this.yBrr)
      } else {
        max = 5
      }
      var option = {
        color: ['#FF6242'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(136, 172, 252, 0.16)',
          borderColor: 'rgba(136, 172, 252, 0.16)',
          textStyle: { color: '#fffff' }
        },
        grid: {
          top: '5%',
          bottom: '10%',
          left: '1%',
          right: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          offset: 5,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { color: '#A5B7CC' },
          data: this.xArr
        },
        yAxis: {
          type: 'value',
          max: max,
          axisLabel: { color: '#A5B7CC' },
          splitLine: {
            lineStyle: {
              color: '#eee',
              width: 0.5,
              type: [2, 10],
              dashOffset: 2
            }
          }
        },
        series: {
          name: '告警数',
          type: 'line',
          symbol: 'none',
          smooth: true,
          data: this.yBrr,
          areaStyle: { opacity: 0.3 }
        }
      }
      echartsRef.setOption(option)
    }
  }
}
</script>

<style scoped>
.one_top{
  padding-top: 20px;
  padding-right: 2%;
  box-sizing: border-box;
  z-index: 10;
}
.setSe >>> .el-input__inner{
  background: rgba(136, 172, 252, 0.16);
  border: none;
  color: #A5B7CC;
}
.boxEch{
  width: 100%;
  height: calc(100% - 55px);
}
</style>
