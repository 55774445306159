<template>
  <div class="box100 threeBox">
    <div style="width: 100%;height: 100%;" ref="panelESeven"></div>
  </div>
</template>

<script>
import { answerRate } from '@/http/api/datapanel'
var echarts = require('echarts')
export default {
  name: 'echartsSeven',
  data () {
    return {
      companyid: '',
      hours1: 0,
      hours12: 0,
      hours24: 0
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    init () {
      answerRate({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.hours1 = res.data.hours1
          this.hours12 = res.data.hours12
          this.hours24 = res.data.hours24
        } else {
          this.hours1 = 0
          this.hours12 = 0
          this.hours24 = 0
        }
        this.drawEcharts()
      })
    },
    drawEcharts () {
      var echartsRef = echarts.init(this.$refs.panelESeven)
      var option = {
        color: ['#33D162', '#4295FF', '#FF6242'],
        legend: {
          itemWidth: 10,
          itemHeight: 10,
          orient: 'horizontal',
          bottom: 10,
          left: 'center',
          textStyle: { color: '#A5B7CC', fontSize: 12, lineHeight: 12 },
          data: ['1小时响应', '12小时响应', '24小时响应']
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['38%', '56%'],
            hoverAnimation: false,
            top: -20,
            left: 'center',
            label: {
              formatter: '{d}% ({c}次)',
              color: '#9BEBF7',
              overflow: 'auto'
            },
            labelLine: { length: 10 },
            data: [
              { value: this.hours1, name: '1小时响应' },
              { value: this.hours12, name: '12小时响应' },
              { value: this.hours24, name: '24小时响应' }
            ]
          },
          {
            name: '',
            type: 'pie',
            radius: ['65%', '66%'],
            hoverAnimation: false,
            top: -20,
            left: 'center',
            label: { show: false },
            itemStyle: {
              color: '#0B576E'
            },
            data: [{ value: 100 }]
          },
          {
            name: '',
            type: 'pie',
            radius: ['0%', '28%'],
            hoverAnimation: false,
            top: -20,
            left: 'center',
            label: { show: false },
            itemStyle: {
              color: 'rgba(136, 172, 252, 0.16)'
            },
            data: [{ value: 100 }]
          }]
      }
      echartsRef.setOption(option)
    }
  }
}
</script>

<style scoped>

</style>
