<template>
  <div class="box100 sixBox">
    <div class="six_top">
      <p style="font-size: 18px;">告警总数</p>
      <p class="alarmAllNum">{{ allNum }}</p>
    </div>
    <div class="boxEch flexCB">
      <div class="sixItem">
        <div class="sixEch" ref="panelESixO"></div>
        <p class="sixPro">占比：{{ fireProp }}%</p>
        <p class="sixNum">数量：{{ fireNum }}</p>
      </div>
      <div class="sixItem">
        <div class="sixEch" ref="panelESixT"></div>
        <p class="sixPro">占比：{{ faultProp }}%</p>
        <p class="sixNum">数量：{{ faultNum }}</p>
      </div>
      <div class="sixItem">
        <div class="sixEch" ref="panelESixTh"></div>
        <p class="sixPro">占比：{{ dangerProp }}%</p>
        <p class="sixNum">数量：{{ dangerNum }}</p>
      </div>
      <div class="sixItem">
        <div class="sixEch" ref="panelESixF"></div>
        <p class="sixPro">占比：{{ otherProp }}%</p>
        <p class="sixNum">数量：{{ otherNum }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { alarmCensus } from '@/http/api/datapanel'
var echarts = require('echarts')
export default {
  name: 'echartsSix',
  data () {
    return {
      companyid: '',
      allNum: 0,
      fireNum: 0,
      fireProp: 0,
      faultNum: 0,
      faultProp: 0,
      dangerNum: 0,
      dangerProp: 0,
      otherNum: 0,
      otherProp: 0
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    init () {
      alarmCensus({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          this.allNum = res.data.zs
          this.fireNum = res.data.hj
          this.faultNum = res.data.gz
          this.dangerNum = res.data.yh
          this.otherNum = res.data.qt
          if (this.allNum !== 0) {
            this.fireProp = ((Number(this.fireNum) / Number(this.allNum)) * 100).toFixed(2)
            this.faultProp = ((Number(this.faultNum) / Number(this.allNum)) * 100).toFixed(2)
            this.dangerProp = ((Number(this.dangerNum) / Number(this.allNum)) * 100).toFixed(2)
            this.otherProp = ((Number(this.otherNum) / Number(this.allNum)) * 100).toFixed(2)
          } else {
            this.fireProp = 0
            this.faultProp = 0
            this.dangerProp = 0
            this.otherProp = 0
          }
          this.getSixOne()
          this.getSixTwo()
          this.getSixThr()
          this.getSixFour()
        } else {
          this.allNum = 0
          this.fireNum = 0
          this.faultNum = 0
          this.dangerNum = 0
          this.otherNum = 0
          this.fireProp = 0
          this.faultProp = 0
          this.dangerProp = 0
          this.otherProp = 0
        }
      })
    },
    // 火警信息
    getSixOne () {
      var echartsRef = echarts.init(this.$refs.panelESixO)
      var option = {
        title: {
          show: true,
          text: '火警信息',
          top: 'center',
          left: 'center',
          textStyle: { color: '#fff', fontSize: 12, lineHeight: 62 }
        },
        series: [
          {
            type: 'pie',
            radius: ['0%', '80%'],
            hoverAnimation: false,
            itemStyle: { normal: { color: 'rgba(136, 172, 252, 0.16)', labelLine: { show: false } } },
            data: [{ value: 0 }]
          },
          {
            type: 'pie',
            radius: ['60%', '100%'],
            hoverAnimation: false,
            data: [
              {
                name: '火警信息',
                value: this.fireProp,
                label: { normal: { show: false } },
                itemStyle: {
                  borderWidth: 0,
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#FF4242' },
                      { offset: 1, color: '#FF6942' }
                    ])
                  }
                }
              },
              {
                name: '火警信息',
                value: 100 - this.fireProp,
                label: { normal: { show: false } },
                itemStyle: { color: 'rgba(136, 172, 252, 0.01)' }
              }
            ]
          }
        ]
      }
      echartsRef.setOption(option)
    },
    // 故障
    getSixTwo () {
      var echartsRef2 = echarts.init(this.$refs.panelESixT)
      var option = {
        title: {
          show: true,
          text: '故障',
          top: 'center',
          left: 'center',
          textStyle: { color: '#fff', fontSize: 12, lineHeight: 62 }
        },
        series: [
          {
            type: 'pie',
            radius: ['0%', '80%'],
            hoverAnimation: false,
            itemStyle: { normal: { color: 'rgba(136, 172, 252, 0.16)', labelLine: { show: false } } },
            data: [{ value: 0 }]
          },
          {
            type: 'pie',
            radius: ['60%', '100%'],
            hoverAnimation: false,
            data: [
              {
                name: '故障',
                value: this.faultProp,
                label: { normal: { show: false } },
                itemStyle: {
                  borderWidth: 0,
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#FF8342' },
                      { offset: 1, color: '#FFD342' }
                    ])
                  }
                }
              },
              {
                name: '故障',
                value: 100 - this.faultProp,
                label: { normal: { show: false } },
                itemStyle: { color: 'rgba(136, 172, 252, 0.01)' }
              }
            ]
          }
        ]
      }
      echartsRef2.setOption(option)
    },
    // 隐患
    getSixThr () {
      var echartsRef3 = echarts.init(this.$refs.panelESixTh)
      var option = {
        title: {
          show: true,
          text: '隐患',
          top: 'center',
          left: 'center',
          textStyle: { color: '#fff', fontSize: 12, lineHeight: 62 }
        },
        series: [
          {
            type: 'pie',
            radius: ['0%', '80%'],
            hoverAnimation: false,
            itemStyle: { normal: { color: 'rgba(136, 172, 252, 0.16)', labelLine: { show: false } } },
            data: [{ value: 0 }]
          },
          {
            type: 'pie',
            radius: ['60%', '100%'],
            hoverAnimation: false,
            data: [
              {
                name: '隐患',
                value: this.dangerProp,
                label: { normal: { show: false } },
                itemStyle: {
                  borderWidth: 0,
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#8D42FF' },
                      { offset: 1, color: '#42A7FF' }
                    ])
                  }
                }
              },
              {
                name: '隐患',
                value: 100 - this.dangerProp,
                label: { normal: { show: false } },
                itemStyle: { color: 'rgba(136, 172, 252, 0.01)' }
              }
            ]
          }
        ]
      }
      echartsRef3.setOption(option)
    },
    // 其他
    getSixFour () {
      var echartsRef4 = echarts.init(this.$refs.panelESixF)
      var option = {
        title: {
          show: true,
          text: '其他',
          top: 'center',
          left: 'center',
          textStyle: { color: '#fff', fontSize: 12, lineHeight: 62 }
        },
        series: [
          {
            type: 'pie',
            radius: ['0%', '80%'],
            hoverAnimation: false,
            itemStyle: { normal: { color: 'rgba(136, 172, 252, 0.16)', labelLine: { show: false } } },
            data: [{ value: 0 }]
          },
          {
            type: 'pie',
            radius: ['60%', '100%'],
            hoverAnimation: false,
            data: [
              {
                name: '其他',
                value: this.otherProp,
                label: { normal: { show: false } },
                itemStyle: {
                  borderWidth: 0,
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#4294FF' },
                      { offset: 1, color: '#42EFFF' }
                    ])
                  }
                }
              },
              {
                name: '其他',
                value: 100 - this.otherProp,
                label: { normal: { show: false } },
                itemStyle: { color: 'rgba(136, 172, 252, 0.01)' }
              }
            ]
          }
        ]
      }
      echartsRef4.setOption(option)
    }
  }
}
</script>

<style scoped>
.six_top{
  padding-top: 20px;
  padding-right: 2%;
  box-sizing: border-box;
  z-index: 10;
}
.alarmAllNum{
  height: 50px;
  line-height: 50px;
  font-size: 38px;
}
.boxEch{
  width: 100%;
  height: calc(100% - 94px);
}
.sixItem{
  /*width: 25%;*/
  height: 100%;
  position: relative;
  text-align: center;
}
.sixEch{
  width: 90px;
  height: 90px;
  margin-bottom: 8px;
}
.sixPro,.sixNum{
  font-size: 14px;
}
</style>
