<template>
  <div class="box100 twoBox">
    <div style="width: 100%;height: 100%;" ref="panelETwo"></div>
    <div v-if="!notData" class="notDataBox">
      <img src="../../assets/img/notdata.png" style="width: 150px;"/>
      <p style="color: #A5B7CC;">暂无设备</p>
    </div>
  </div>
</template>

<script>
import { equNumber } from '@/http/api/datapanel'
var echarts = require('echarts')
export default {
  name: 'echartsTwo',
  data () {
    return {
      companyid: '',
      xArr: [],
      yBrr: [],
      notData: false
    }
  },
  mounted () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    init () {
      equNumber({ companyid: this.companyid }).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            this.notData = true
            for (var i in res.data) {
              this.xArr.push(res.data[i].equtypename)
              this.yBrr.push(res.data[i].allcount)
            }
            this.drawEcharts()
          } else {
            this.notData = false
          }
        } else {
          this.notData = false
        }
      })
    },
    drawEcharts () {
      var echartsRef = echarts.init(this.$refs.panelETwo)
      var endPercent = (6 / this.xArr.length) * 150
      var max = Math.max(...this.yBrr)
      if (max > 5) {
        max = Math.max(...this.yBrr)
      } else {
        max = 5
      }
      var option = {
        grid: {
          top: 30,
          bottom: 10,
          left: '1%',
          right: '8%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(136, 172, 252, 0.16)',
          borderColor: 'rgba(136, 172, 252, 0.16)',
          textStyle: { color: '#fffff' }
        },
        dataZoom: {
          start: 0, // 默认为0
          end: endPercent,
          type: 'inside',
          show: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          offset: 5,
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            color: '#A5B7CC',
            interval: 0,
            rotate: -45,
            fontSize: 12
          },
          data: this.xArr
        },
        yAxis: {
          type: 'value',
          max: max,
          axisLabel: { color: '#A5B7CC' },
          splitLine: {
            lineStyle: {
              color: '#eee',
              width: 0.5,
              type: [2, 10],
              dashOffset: 2
            }
          }
        },
        series: {
          name: '设备数量',
          type: 'bar',
          barWidth: '12px',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0, color: '#42EFFF'
              }, {
                offset: 1, color: '#4294FF'
              }], false)
            }
          },
          data: this.yBrr
        }
      }
      echartsRef.setOption(option)
    }
  }
}
</script>

<style scoped>

</style>
